let production = false;
let apiUrl = "http://localhost:8080/api";
let testing = false;

if (process.env.NODE_ENV === "production") {
    (production = true), (apiUrl = "https://jouluruoka.veerola.fi/api");
} else if (process.env.NODE_ENV === "testing") {
    testing = true;
}

export const environment = {
    production: production,
    apiUrl: apiUrl,
    testing: testing,
};
