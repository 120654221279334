var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { updateFoodsEvent } from "./constants";
export var updateFoods = function () {
    document.dispatchEvent(new CustomEvent(updateFoodsEvent));
};
export var bindAll = function (thisObject) {
    var functionNames = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        functionNames[_i - 1] = arguments[_i];
    }
    functionNames.forEach(function (name) {
        var member = thisObject[name];
        if (typeof member === "function") {
            member = member.bind(thisObject);
        }
    });
};
var BaseElement = /** @class */ (function () {
    function BaseElement(options) {
        this.node = createElement(options);
        return this;
    }
    BaseElement.prototype.getElement = function () {
        return this.node;
    };
    BaseElement.prototype.render = function (parent) {
        var parentById = document.getElementById(parent);
        if (parentById !== null) {
            parentById.appendChild(this.node);
        }
        var parentByClass = document.querySelector(parent);
        if (parentByClass !== null) {
            parentByClass.appendChild(this.node);
        }
    };
    BaseElement.prototype.createChildElement = function (options) {
        var child = createElement(options);
        this.node.appendChild(child);
        return this;
    };
    BaseElement.prototype.addChild = function (element) {
        this.node.appendChild(element);
        return this;
    };
    BaseElement.prototype.removeContent = function () {
        while (this.node.lastElementChild) {
            this.node.removeChild(this.node.lastElementChild);
        }
        return this;
    };
    return BaseElement;
}());
export { BaseElement };
var BaseComponent = /** @class */ (function () {
    function BaseComponent(options) {
        this.node = new BaseElement(options);
    }
    BaseComponent.prototype.getElement = function () {
        return this.node.getElement();
    };
    return BaseComponent;
}());
export { BaseComponent };
export var createElement = function (options) {
    var node = document.createElement(options.type);
    var className = options.className, text = options.text, type = options.type, attributes = __rest(options, ["className", "text", "type"]);
    if (options.className) {
        node.setAttribute("class", options.className);
    }
    if (options.text) {
        node.textContent = options.text;
    }
    Object.entries(attributes).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        node.setAttribute(key, value);
    });
    return node;
};
export var addChildElement = function (parent, options) {
    var child = createElement(options);
    parent.appendChild(child);
    return parent;
};
