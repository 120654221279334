var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment } from "../../environment";
export var getFoods = function () {
    var userId = localStorage.getItem("userId") || -1;
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            var foods = JSON.parse(xmlhttp.responseText);
            resolve(foods.map(function (food) { return (__assign(__assign({}, food), { likes: Number(food.likes), dislikes: Number(food.dislikes) })); }));
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("GET", environment.apiUrl + ("/get-foods.php?userId=" + userId), true);
        xmlhttp.send();
    });
};
export var addFood = function (foodName, info) {
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            if (xmlhttp.status === 200) {
                resolve();
            }
            else {
                reject();
            }
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("POST", environment.apiUrl + "/add-food.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send("foodName=" + encodeURIComponent(foodName) + "&info=" + info);
    });
};
export var deleteFood = function (name) { };
