import { environment } from "../../environment";
export var addComment = function (foodId, comment) {
    var userId = localStorage.getItem("userId") || -1;
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            if (xmlhttp.status === 200) {
                resolve();
            }
            else {
                reject();
            }
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("POST", environment.apiUrl + "/add-comment.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send("userId=" + userId + "&foodId=" + foodId + "&comment=" + comment);
    });
};
