var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { updateFoodsEvent } from "../constants";
import { getFoods } from "../services/foodService";
import { dislikeFood, likeFood, removeLike } from "../services/likeService";
import { BaseComponent, BaseElement, createElement, updateFoods, } from "../utils";
import { CommentList } from "./commentList";
var FoodList = /** @class */ (function () {
    function FoodList() {
        this.updateFoods = this.updateFoods.bind(this);
        this.render = this.render.bind(this);
        document.addEventListener(updateFoodsEvent, this.updateFoods);
        this.node = new BaseElement({
            type: "ul",
            className: "list__container",
        });
        this.node.render(".main-content");
        this.listItems = [];
        this.updateFoods();
    }
    FoodList.prototype.updateFoods = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, getFoods()];
                    case 1:
                        _a.foods = _b.sent();
                        this.foods.forEach(function (food, index) {
                            if (index < _this.listItems.length) {
                                _this.listItems[index].updateFood(food);
                            }
                            else {
                                _this.listItems.push(new FoodListItem(food));
                            }
                        });
                        this.render();
                        return [2 /*return*/];
                }
            });
        });
    };
    FoodList.prototype.render = function () {
        var _this = this;
        this.listItems.forEach(function (item) {
            if (!document.body.contains(item.getElement())) {
                _this.node.getElement().appendChild(item.getElement());
            }
        });
    };
    return FoodList;
}());
export { FoodList };
var FoodListItem = /** @class */ (function () {
    function FoodListItem(food) {
        this.food = food;
        this.node = new BaseElement({ type: "li", className: "list__item" });
        this.foodNameComponent = new FoodName(this.food);
        this.updateContent();
    }
    FoodListItem.prototype.updateFood = function (newFood) {
        var _this = this;
        var hasChanged = Object.entries(newFood).some(function (_a) {
            var key = _a[0], value = _a[1];
            return value !== _this.food[key];
        });
        if (hasChanged) {
            this.food = newFood;
            this.updateContent();
        }
    };
    FoodListItem.prototype.getElement = function () {
        return this.node.getElement();
    };
    FoodListItem.prototype.updateContent = function () {
        this.foodNameComponent.updateContent(this.food);
        return this.node
            .removeContent()
            .addChild(this.foodNameComponent.getElement())
            .addChild(getLikeButtons(this.food))
            .getElement();
    };
    return FoodListItem;
}());
export { FoodListItem };
var FoodName = /** @class */ (function (_super) {
    __extends(FoodName, _super);
    function FoodName(food) {
        var _this = _super.call(this, { type: "div", className: "list__name-container" }) || this;
        _this.food = food;
        _this.commentList = new CommentList(_this.food.id);
        return _this;
    }
    FoodName.prototype.updateContent = function (newFood) {
        this.food = newFood;
        this.commentList.updateComments(newFood.comments);
        this.render();
    };
    FoodName.prototype.render = function () {
        this.node
            .removeContent()
            .createChildElement({
            type: "div",
            className: "list__name",
            text: this.food.foodName + " (" + this.food.info + ")",
        })
            .addChild(this.commentList.getElement());
    };
    return FoodName;
}(BaseComponent));
export { FoodName };
var getLikeButtons = function (food) {
    var buttonContainer = createElement({
        type: "div",
        className: "list__buttons",
    });
    var likeButton = createElement({
        type: "button",
        className: "list__button list__button--" + (food.isLiked ? "liked" : "unset"),
        text: "\uD83D\uDC4D (" + food.likes + ")",
    });
    likeButton.onclick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!food.isLiked) return [3 /*break*/, 2];
                    return [4 /*yield*/, removeLike(food.id)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 2:
                    if (!food.isDisliked) return [3 /*break*/, 4];
                    return [4 /*yield*/, removeLike(food.id)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, likeFood(food.id)];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    updateFoods();
                    return [2 /*return*/];
            }
        });
    }); };
    buttonContainer.appendChild(likeButton);
    var dislikeButton = createElement({
        type: "button",
        className: "list__button list__button--" + (food.isDisliked ? "disliked" : "unset"),
        text: "\uD83D\uDC4E (" + food.dislikes + ")",
    });
    dislikeButton.onclick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!food.isDisliked) return [3 /*break*/, 2];
                    return [4 /*yield*/, removeLike(food.id)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 2:
                    if (!food.isDisliked) return [3 /*break*/, 4];
                    return [4 /*yield*/, removeLike(food.id)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, dislikeFood(food.id)];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    updateFoods();
                    return [2 /*return*/];
            }
        });
    }); };
    buttonContainer.appendChild(dislikeButton);
    return buttonContainer;
};
