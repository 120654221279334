var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { addComment } from "../services/commentService";
import { BaseComponent, BaseElement, createElement, updateFoods, } from "../utils";
var CommentList = /** @class */ (function (_super) {
    __extends(CommentList, _super);
    function CommentList(foodId) {
        var _this = _super.call(this, { type: "div", className: "comment__container" }) || this;
        _this.foodId = foodId;
        _this.comments = [];
        _this.isCommentsExpanded = false;
        _this.expandComments = _this.expandComments.bind(_this);
        _this.collapseComments = _this.collapseComments.bind(_this);
        return _this;
    }
    CommentList.prototype.updateComments = function (newComments) {
        this.comments = newComments;
        this.render();
    };
    CommentList.prototype.expandComments = function () {
        this.isCommentsExpanded = true;
        this.render();
    };
    CommentList.prototype.collapseComments = function () {
        this.isCommentsExpanded = false;
        this.render();
    };
    CommentList.prototype.render = function () {
        var _this = this;
        this.node.removeContent();
        if (!this.isCommentsExpanded) {
            this.node.addChild(getCollapsedCommentIndicator({
                expandComments: this.expandComments,
                commentCount: this.comments.length,
            }));
        }
        else {
            this.node.addChild(getExpandedCommentIndicator({
                collapseComments: this.collapseComments,
                commentCount: this.comments.length,
            }));
            this.comments.forEach(function (comment) {
                _this.node.createChildElement({
                    type: "div",
                    className: "comment__text",
                    text: comment,
                });
            });
            this.node.addChild(getCommentInput(this.foodId));
        }
    };
    return CommentList;
}(BaseComponent));
export { CommentList };
var getCommentInput = function (foodId) {
    var submitButton = createElement({
        type: "button",
        className: "comment__input-button",
        text: "OK",
    });
    submitButton.onclick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var comment;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    comment = document.getElementById("comment-input").value;
                    return [4 /*yield*/, addComment(foodId, comment)];
                case 1:
                    _a.sent();
                    updateFoods();
                    return [2 /*return*/];
            }
        });
    }); };
    return new BaseElement({
        type: "div",
        className: "comment__input-container",
    })
        .createChildElement({
        type: "input",
        className: "comment__input-input",
        id: "comment-input",
        placeholder: "Kirjoita kommentti",
    })
        .addChild(submitButton)
        .getElement();
};
var getCollapsedCommentIndicator = function (options) {
    var handleClick = function () {
        options.expandComments();
    };
    var commentText = options.commentCount + " kommentti" + (options.commentCount > 1 ? "a" : "");
    var element = createElement({
        type: "div",
        className: "comment__indicator comment__indicator--collapsed",
        text: options.commentCount === 0 ? "Ei kommentteja" : commentText,
    });
    element.addEventListener("click", handleClick);
    return element;
};
var getExpandedCommentIndicator = function (options) {
    var handleClick = function () {
        options.collapseComments();
    };
    var element = createElement({
        type: "div",
        className: "comment__indicator comment__indicator--expanded",
        text: "Piilota kommentit",
    });
    element.addEventListener("click", handleClick);
    return element;
};
