import { environment } from "../../environment";
export var likeFood = function (foodId) { return addLike(foodId, 1); };
export var dislikeFood = function (foodId) {
    return addLike(foodId, 0);
};
var addLike = function (foodId, isLike) {
    var userId = localStorage.getItem("userId") || -1;
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            if (xmlhttp.status === 200) {
                resolve();
            }
            else {
                reject();
            }
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("POST", environment.apiUrl + "/add-like.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send("userId=" + userId + "&foodId=" + foodId + "&isLike=" + isLike);
    });
};
export var removeLike = function (foodId) {
    var userId = localStorage.getItem("userId") || -1;
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            if (xmlhttp.status === 200) {
                resolve();
            }
            else {
                reject();
            }
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("GET", environment.apiUrl +
            ("/delete-like.php?userId=" + userId + "&foodId=" + foodId), true);
        xmlhttp.send();
    });
};
