import { environment } from "../../environment";
import { updateFoods } from "../utils";
export var getUserName = function (id) {
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            if (xmlhttp.status === 200) {
                var userName = xmlhttp.responseText;
                getUserId(userName).then(function (userId) {
                    if (userId && userId !== id) {
                        localStorage.setItem("userId", userId);
                        updateFoods();
                    }
                });
                resolve(xmlhttp.responseText);
            }
            else {
                resolve(undefined);
            }
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("GET", environment.apiUrl + ("/get-user-name.php?userId=" + id), true);
        xmlhttp.send();
    });
};
export var getUserId = function (userName) {
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            if (xmlhttp.status === 200) {
                resolve(xmlhttp.responseText);
            }
            else {
                resolve(undefined);
            }
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("GET", environment.apiUrl + ("/get-user-id.php?userName=" + userName), true);
        xmlhttp.send();
    });
};
export var setUser = function (name) {
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = function () {
            if (xmlhttp.status === 200) {
                resolve(xmlhttp.responseText);
            }
            else {
                resolve(undefined);
            }
        };
        xmlhttp.onerror = function () { return reject(xmlhttp.statusText); };
        xmlhttp.open("POST", environment.apiUrl + "/add-user.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send("userName=" + name);
    });
};
